(function () {
  const sections = document.querySelectorAll('.intersection-observable');
  const navItems = document.querySelectorAll('.nav-item');
  let activeSection = undefined;
  let activeNavItem = undefined;

  window.addEventListener('scroll', function() {

    let candidateSection = [...sections].filter(x => x.getBoundingClientRect().top <= 1).at(-1);

    if (candidateSection !== activeSection) {

      const candidateNavItem = candidateSection ? document.querySelector(`#${candidateSection.dataset.navItemId}`) : undefined;
      
      if (activeSection) {
        activeNavItem.classList.remove('active');
      }
      
      if (activeNavItem) {
        activeNavItem.classList.remove('active');
      }

      if (candidateSection) {
        candidateSection.classList.add('active');
      }
      
      if (candidateNavItem) {
        candidateNavItem.classList.add('active');
      }
      
      activeSection = candidateSection;
      activeNavItem = candidateNavItem;
    }
  });
})();